import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { NavDropdown } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from 'styled-components';
import React, { useState, useContext } from 'react';
import { navigate, Link } from 'gatsby';
import { useStaticQuery, graphql } from 'gatsby';
import Search from '../Search';
import SubMenu from './subMenu';
import ImageComponent from '../../utils/imageComponent';
import CustomContext from '../../../custom-context';
import LinkComponent from '../../utils/linkComponent';
const searchIndices = [
	{ name: `Pages`, title: `Pages` },
	{ name: `Resources`, title: `Resources` },
	{ name: `Events`, title: `Events` },
];

const StyledLink = styled((props) => <LinkComponent {...props} />)`
	font-size: var(--font-size-md);
	margin: 0em 0.7em;
	/* color: var(--secondary_2) !important; */
	padding: 10px 0px;
	color: var(--primary);
	text-decoration: none;
	white-space: nowrap;
	align-self: center;
	font-weight: 700;

	/* add onhover css to change color of the link */
	&:hover {
		color: var(--secondary_3);
		text-decoration: none;
	}

	// mobile settings
	@media (max-width: 1199.5px) {
		font-weight: 600;
		margin-left: 0em;
		align-self: start;
		.mainMenu {
			top: 0px !important;
		}
	}
`;

const NavStyled = styled.div`
	position: sticky;
	top: -1px;
	z-index: 1000;
	background-color: #f5f5f5;

	.logo {
		width: 6em;
	}

	.login .nav-link {
		font-size: var(--font-size-md);
		margin: 0em 0.7em;
		/* color: var(--secondary_2) !important; */
		padding: 10px 0px;
		color: var(--primary);
		text-decoration: none;
		white-space: nowrap;
		align-self: center;
		font-weight: 700;
	}
	.dropdown-menu {
		border-radius: 8px;
		box-shadow: 0 4px 16px rgb(46 41 51 / 8%), 0 8px 24px rgb(71 63 79 / 16%);
		padding: 36px;
		border: none;
	}

	.dropdown-toggle::after {
		content: none;
	}
	.dropdown-item {
		font-family: var(--hero_title_font_family);
		font-weight: 500 !important;
		font-size: var(--font-size-base);
		padding: 0px;
	}
	.logoutButton {
		font-size: var(--font-size-md);
		color: var(--primary);
		width: 4.1em;
		text-align: center;
		padding: 10px 0px;
		margin: 0em 0.4em;
		text-decoration: none;
		font-weight: 700;
		/* padding: 0.3em; */
		cursor: pointer;
	}

	.mainMenu {
		/* background: var(--secondary_6); */
		position: sticky;
		top: 0;
	}

	.navbar-light .navbar-brand {
		color: var(--secondary_6);
	}

	.navbar-light .navbar-toggler {
		border-color: var(--secondary_7) !important;
		color: var(--secondary_2) !important;
	}
	.logoutButton:hover,
	.dropdown-item:hover,
	.nav-link:hover {
		color: var(--secondary_3) !important;
		background-color: transparent;
		text-decoration: none;
	}

	.nav-item.dropdown:hover .dropdown-menu {
		display: block;
	}
	.sub-menu:hover .dropdown-submenu {
		display: block !important;
	}

	// .navbar-toggler-icon {
	// 	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
	// }

	// tablets settings
	@media (max-width: 1199.5px) {
		.navItem {
			border-bottom: 1px solid #ccc !important;
			border-radius: 0px;
			padding: 10px 0px;
			font-weight: 600;
			font-size: var(--font-size-base) !important;
			width: 100%;
		}
		.dropdown-menu {
			padding: 10px 0px;
		}
		.nav-link {
			font-size: var(--font-size-base) !important;
			margin: 0 !important;
			padding: 0 !important;
		}
		.logoutButton {
			font-size: var(--font-size-base) !important;
			margin: 0 0;
			text-align: left;
		}
		.sub-menu:hover .dropdown-submenu {
			display: contents !important;
		}
	}
	// mobile settings
	@media (max-width: 768px) {
		.navItem {
			border-bottom: 1px solid #ccc !important;
			border-radius: 0px;
			padding: 10px 0px;
			font-weight: 600;
			font-size: var(--font-size-base) !important;
			width: 100%;
		}
		.dropdown-menu {
			padding: 10px 0px;
		}
		.nav-link {
			font-size: var(--font-size-base) !important;
			margin: 0 !important;
			padding: 0 !important;
		}
		.menuButton {
			padding: 0 0;
		}
	}
`;
const Header = () => {
	const [expanded, setExpanded] = useState(false);
	const { user, setUser, setToken } = useContext(CustomContext);
	const menuDataAndLogoData = useStaticQuery(graphql`
		query menuQueryAndStarpiAssetsLogo {
			strapiAsset {
				logo {
					alternativeText
					caption
					url
					localFile {
						childImageSharp {
							gatsbyImageData
						}
					}
				}
			}
			allStrapiNavigationPluginMainNavigation(sort: { order: ASC }) {
				nodes {
					items {
						path
						title
						type
						sub_menu_icon
						items {
							path
							title
							type
						}
					}
					order
					title
					type
					path
				}
			}
		}
	`);
	return (
		<NavStyled>
			<Navbar
				variant='light'
				fixed='top'
				expand='xl'
				className='mainMenu'
				collapseOnSelect
				expanded={expanded}
				onToggle={() => setExpanded((expanded) => !expanded)}
			>
				<Container>
					<Navbar.Brand
						onClick={() => {
							setExpanded(false);
							navigate('/');
						}}
						style={{ cursor: 'pointer', width: '200px' }}
					>
						<ImageComponent
							imageItem={menuDataAndLogoData.strapiAsset.logo}
							alt='Logo'
							className='logo'
						></ImageComponent>
					</Navbar.Brand>
					<Navbar.Toggle aria-controls='basic-navbar-nav' />
					<Navbar.Collapse id='basic-navbar-nav'>
						<Nav className='ms-auto'>
							<Search
								className='rounded'
								setExpanded={setExpanded}
								indices={searchIndices}
							/>
							{/* Adding a multilevel navbar with dropdowns */}
							{menuDataAndLogoData.allStrapiNavigationPluginMainNavigation.nodes.map(
								(menuItem) =>
									menuItem.items?.length > 0 ? (
										<SubMenu
											setExpanded={setExpanded}
											menuItem={menuItem}
										></SubMenu>
									) : (
										<StyledLink
											onClick={() => setExpanded(false)}
											className='navItem'
											to={menuItem.path}
											key={menuItem.title}
										>
											{menuItem.title}
										</StyledLink>
									)
							)}
							{/* <a
								href='https://www.hosaanz.org/content.aspx?page_id=31&club_id=964084&action=login&user=5'
								target='_blank'
								rel='noreferrer'
							>
								<button className='loginButton'> Login</button>
							</a> */}
							{user !== null && user !== undefined ? (
								<StyledLink
									to='/dashboard'
									className='navItem'
									onClick={() => setExpanded(false)}
								>
									{' '}
									Dashboard
								</StyledLink>
							) : null}
							{user !== null && user !== undefined ? (
								<div
									className='logoutButton'
									onClick={() => {
										setUser(null);
										setToken(null);
									}}
									onKeyDown={() => {
										setUser(null);
										setToken(null);
									}}
									role='button'
									tabIndex='0'
								>
									{' '}
									Logout
								</div>
							) : (
								<NavDropdown
									align='end'
									id='nav-dropdown-basic'
									title='Login'
									className='navItem login'
									renderMenuOnMount={true}
									// data-bs-theme='dark'
									// className='loginButton'
								>
									<NavDropdown.Item
										style={{ paddingBottom: '1.5em' }}
										href={`${process.env.GATSBY_AAF_LOGIN_URL}`}
										onClick={() =>
											sessionStorage.setItem('returnURL', window.location.href)
										}
									>
										Login via AAF
									</NavDropdown.Item>
									<NavDropdown.Item
										href={`${process.env.GATSBY_TUAKIRI_LOGIN_URL}`}
										onClick={() =>
											sessionStorage.setItem('returnURL', window.location.href)
										}
									>
										Login via Tuakiri
									</NavDropdown.Item>
									<NavDropdown.Item
										href='/login-help/'
										style={{ paddingTop: '1.5em' }}
									>
										Login help
									</NavDropdown.Item>
								</NavDropdown>
							)}
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</NavStyled>
	);
};
export default Header;
