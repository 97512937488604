import * as React from 'react';
import styled from 'styled-components';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import LinkComponent from '../../utils/linkComponent';

const FooterLinksStyled = styled.div`
	color: var(--secondary_6);
	background-color: var(--primary);
	a:hover {
		color: var(--secondary_3);
		text-decoration: none;
	}

	.footer {
		//margin-bottom: 2em;
		background: var(--secondary_7);
	}
	ul {
		padding-inline-start: 1px !important;
	}

	li {
		list-style: none !important;
	}

	h3 {
		color: var(--secondary_3);
	}
	h4 {
		color: var(--secondary_6);
		margin-top: 1em;
	}
	a {
		color: var(--secondary_6);
		text-decoration: none;
	}

	// mobile settings
	@media (max-width: 768px) {
	}
`;

export default function FooterLinks(data) {
	const footerMenuData = useStaticQuery(graphql`
		query FooterMenuQuery {
			allStrapiNavigationPluginFooterMenu(sort: { order: ASC }) {
				nodes {
					path
					type
					title
					external
					items {
						path
						title
						type
					}
					# slug
				}
			}
		}
	`);
	// Divide the FooterArray into 4 items length array
	var arr = footerMenuData.allStrapiNavigationPluginFooterMenu?.nodes;
	const chunk = 4;
	var footerItemArray = [];
	for (let i = 0; i < arr.length; i += chunk) {
		footerItemArray.push(arr.slice(i, i + chunk));
	}
	return (
		<FooterLinksStyled>
			<div className='footer'>
				<Container>
					{footerItemArray.map((footerChunk, index) => (
						<Row key={index}>
							{footerChunk.map((menuItem) => (
								<Col xs={12} md={6} lg={3} key={menuItem.title}>
									{
										(menuItem.path = '/' ? (
											<h3>{menuItem.title}</h3>
										) : (
											<LinkComponent
												to={menuItem.path}
												onClick={() => {
													window.analytics.track('Link Clicked', {
														page_name: data.props.title,
														title: menuItem?.title ? menuItem.title : null,
														static_component_title: 'Footer',
														link_name: menuItem?.title ? menuItem.title : null,
														link: menuItem?.path ? menuItem.path : null,
														path: window.location.pathname,
														item_title: menuItem?.title ? menuItem.title : null,
														report_type: 'New',
														url: window.location.href,
													});
												}}
											>
												<h3>{menuItem.title}</h3>
											</LinkComponent>
										))
									}
									<ul>
										{menuItem?.items?.map((item) => (
											<li key={item.title}>
												<LinkComponent
													to={item.path}
													onClick={() => {
														window.analytics.track('Link Clicked', {
															page_name: data.props.title,
															title: menuItem?.title ? menuItem.title : null,
															static_component_title: 'Footer',
															link_name: item?.title ? item.title : null,
															link: item?.path ? item.path : null,
															path: window.location.pathname,
															item_title: item?.title ? item.title : null,
															report_type: 'New',
															url: window.location.href,
														});
													}}
												>
													{item.title}
												</LinkComponent>
											</li>
										))}
									</ul>
								</Col>
							))}
						</Row>
					))}
				</Container>
			</div>
		</FooterLinksStyled>
	);
}
