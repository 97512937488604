import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { navigate, Link } from 'gatsby';
import Dropdown from './dropdown';

const SubMenuStyled = styled.div`
	position: relative;
	margin: 0em 0.7em;
	font-size: var(--font-size-md);
	align-self: center;

	.openArrow {
		background: #f1f1f1;
		border-top-left-radius: 2px;
		bottom: -8px;
		height: 16px;
		left: 50%;
		position: absolute;
		-webkit-transform: translateX(calc(-50% - 10px)) rotate(45deg);
		transform: translateX(calc(-50% - 10px)) rotate(45deg);
		width: 16px;
	}

	.menuButton {
		align-items: center;
		border-radius: 4px;
		color: #fff;
		cursor: pointer;
		display: inline-flex;
		font-size: var(--font-size-md);
		padding: 10px 0px;
		position: relative;
		text-decoration: none;
		font-weight: 700;
	}

	.menuButton:hover {
		color: var(--secondary_3);
		text-decoration: none;
	}

	.menuButton1 {
		background: transparent;
		border: 0;
		color: var(--secondary_2);
		font-family: inherit;
		font-size: inherit;
	}
	.menuButton2 {
		white-space: nowrap;
	}

	// mobile settings
	@media (max-width: 1199.5px) {
		margin-left: 0px;
		border-bottom: 1px solid #ccc !important;
		border-radius: 0px;
		align-self: auto;
		.menuButton {
			font-weight: 600;
			font-size: var(--font-size-base);
			/* padding: 10px 0px; */
		}
		.openArrow {
			display: none;
		}
		.accordianArrow {
			background-color: #f5f5fa;
			margin-left: 0.5em;
		}
		.accordianArrowClose {
			transform: rotate(180deg);
		}
	}
	// mobile settings
	@media (max-width: 768px) {
		border-bottom: 1px solid #ccc !important;
		border-radius: 0px;
		padding: 10px 0px;
		width: 100%;
	}
`;
export default function SubMenu(props) {
	const { menuItem } = props;
	const [dropdown, setDropdown] = useState(false);
	//for detecting clicks outside of the dropdown
	let ref = useRef();

	// detect clicks outside of the dropdown
	useEffect(() => {
		const handler = (event) => {
			if (dropdown && ref.current && !ref.current.contains(event.target)) {
				setDropdown(false);
			}
		};
		document.addEventListener('mousedown', handler);
		document.addEventListener('touchstart', handler);
		return () => {
			// Cleanup the event listener
			document.removeEventListener('mousedown', handler);
			document.removeEventListener('touchstart', handler);
		};
	}, [dropdown]);

	return (
		<SubMenuStyled ref={ref} className='sub-menu'>
			<button
				aria-haspopup='menu'
				aria-expanded={dropdown ? 'true' : 'false'}
				onClick={() => setDropdown((prev) => !prev)}
				className='menuButton menuButton1 menuButton2'
			>
				{menuItem.title}
				{dropdown && <span className='openArrow'></span>}
			</button>
			{/* {dropdown && ( */}
			<Dropdown
				setDropdown={setDropdown}
				setExpanded={props.setExpanded}
				items={menuItem.items}
				dropdown={dropdown}
			/>
			{/* )} */}
		</SubMenuStyled>
	);
}
